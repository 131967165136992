<template>
  <v-list-item @click="dialog = true">
    <gene-result-dialog @close="dialog = false" :active="dialog" :geneData="geneData"/>

    <v-list-item-content>
      <v-list-item-title>
        <span class="font-weight-medium">{{ geneData.symbol }}</span> -
        {{geneData.name}}
      </v-list-item-title>
      <v-list-item-subtitle class="">
        {{ geneData.genotype }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-icon :color="geneData.color" large class="mr-2">mdi-circle</v-icon
    >
  </v-list-item>
</template>

<script>
import GeneResultMixin from "@/mixins/GeneResultMixin.vue"
import GeneResultDialog from "@/components/mylogy/pgx_results/GeneResultDialog.vue"
export default {
  name: "SearchbaseGeneResultItem",
  components: {GeneResultDialog},
  mixins: [GeneResultMixin],
  data() {
    return {
      dialog: false
    };
  },
};
</script>

<style lang="scss" scoped>
  .left-border {
  border-left: solid 2px #4D2CB9;
  display: flex;
}
.v-card .v-card__actions {
  background: #ffffff00;
  transition: .3s;
  box-shadow: 0 5px 30px -20px inset #310ab1;
} 
</style>