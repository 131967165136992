<template>
  <v-list-item @click="dialog = true" two-line>
    <drug-result-dialog @close="dialog = false" :active="dialog" :drugData="drugData"/>
    <v-list-item-content>
      <v-list-item-title class="font-weight-medium">
        {{ name }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="getDrugResult(drug)[0].gene != ''">
        {{ recomendation }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-icon :color="color" large class="mr-2">mdi-circle</v-icon>
  </v-list-item>
</template>

<script>

import DrugResultMixin from "@/mixins/DrugResultMixin.vue";
import DrugResultDialog from "@/components/mylogy/pgx_results/DrugResultDialog.vue"

export default {
  name: "SearchbaseDrugResultItem",
  components: {DrugResultDialog},
  mixins: [DrugResultMixin],
  data() {
    return {
      dialog: false
    };
  }
};
</script>

<style lang="scss" scoped>
.v-card .v-card__actions {
  background: #ffffff00;
  transition: 0.3s;
  box-shadow: 0 5px 40px -30px inset #310ab1;
}
</style>
