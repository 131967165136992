<template>
  <v-container id="explorer" fluid tag="section">
    <fondo-custom></fondo-custom>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-pill</template>
          <template v-slot:toptitle-text>{{ $t("farmacogenetica") }}</template>
        </page-top-title>
      </v-col>
    </v-row>

    <reactive-base
      :app="app"
      :url="url"
      :key="searchBaseKey"
      :credentials="credentials"
      :type="type"
    >
      <!-- RESULTADOS -->
      <v-row justify="center">
        <v-col cols="12" md="7">
          <v-card class="pa-0" rounded="lg">
            <v-card-title class="solid-heading rounded-lg py-0 px-10">
              <v-icon large color="white" class="mr-4 mt-1">mdi-magnify</v-icon>
              <data-search :innerClass="{   input: 'reactive-search-input' }" :showIcon="false" :showClear="false" :URLParams="true" :placeholder="searchPlaceholder" :filterLabel="$t('nombre')" componentId="SearchSensor" :autosuggest="true" :dataField="datafields" class="grow"/>
            </v-card-title>
            <v-card-text>
              <v-row class="px-sm-4 search-bar d-flex mt-4">
                <like-radio-buttons
                  @buttonSelected="buttonSelected"
                  :activeButtonKey="app"
                  :buttons="[
                    { text: $t('Medicamentos'), icon: 'pill', key: 'drugs' },
                    { text: $t('Genes'), icon: 'dna', key: 'genes' }
                  ]"
                  class="buttons d-flex flex-wrap mb-2"
                />
              </v-row>

              <v-row>
                <v-list class="searchbase-results" rounded>
                  <v-list-item-group color="primary">
                    <selected-filters
                      :clearAllLabel="$t('limpiar')"
                      :showClearAll="true"
                    />
                    <ReactiveList
                      componentId="result"
                      dataField="Critic_Score"
                      class="results"
                      @data="resultsDataChanged"
                      :sortOptions="sortOptions"
                      :innerClass="{ resultStats: 'result-stat' }"
                      :defaultQuery="defaultQuery"
                      pagination
                      pages="4"
                      :size="8"
                      :showResultStats="false"
                      :react="reactiveListReact"
                    >
                      <div slot="renderItem" slot-scope="{ item }">
                        <searchbase-drug-result-item
                          v-if="app == 'drugs'"
                          :drug="item"
                        />
                        <searchbase-gene-result-item v-else :gene="item" />
                      </div>
                    </ReactiveList>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-card-text>
            <v-card-actions  v-if="app == 'drugs'">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header hide-actions>
                    <v-col cols="8" sm="9" class="px-0"><v-divider></v-divider></v-col>
                    <v-col cols="4" sm="3" class="px-0"><v-btn rounded="pill" outlined block class="text-align-right" color="primary">{{$t('leyenda')}} <v-icon>mdi-chevron-down</v-icon></v-btn></v-col>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-4">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <div class="d-flex my-2">
                          <v-icon color="grey" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                            {{$t('no_analizado')}}
                          </p>
                        </div>
                        <div class="d-flex my-2">
                          <v-icon color="#b0e0e6" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                            {{$t('impacto_farmacogenetico_limitado')}}
                          </p>
                        </div>
                        <div class="d-flex my-2">
                          <v-icon color="green" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                            {{$t('respuesta_normal')}}
                          </p>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <div class="d-flex my-2">
                          <v-icon color="yellow" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                            {{$t('respuesta_anomala_toxicidad_moderada')}}
                          </p>
                        </div>
                        <div class="d-flex my-2">
                          <v-icon color="orange" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                          {{$t('respuesta_anomala_riesgo_de_ineficacia_terapeutica')}}
                          </p>
                        </div>
                        <div class="d-flex my-2">
                          <v-icon color="red" class="mb-1">mdi-square-rounded</v-icon>
                          <p  class="text-h5 ma-auto ml-2">
                            {{$t('respuesta_anomala_riesgo_de_toxicidad_elevado')}}
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                    
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" md="4" lg="3">
          <v-card v-if="app == 'drugs'" class="pa-0" rounded="lg">
            <v-card-title class="solid-heading rounded-lg mb-4 py-3">
              <h3 class="white--text font-weight-regular"> <v-icon color="white">mdi-filter</v-icon> Filtros</h3>
            </v-card-title>
            <v-card-text>
              <v-select  @input="updateModel"  v-model="selectedModel"  :items="models"  item-text="name"  item-value="val"  persistent-hint  return-object  single-line  class="rounded-pill"></v-select>

              <h3 class="title text-h3 primary--text mt-8">{{$t('Por enfermedades')}}</h3>
              <data-search :innerClass="{   input: 'reactive-search-input' }" :showIcon="false" :URLParams="true"  :showClear="false" :filterLabel="$t('enfermedades')" :placeholder="$t('buscar enfermedades')" componentId="DrugSearchDisesasesSensor" :autosuggest="true" :dataField="drugDiseaseDatafields" class="mr-4 mb-4 grow"/>

              <h3 class="title text-h3 primary--text mt-12">{{$t('Por fármaco')}}</h3>
              <single-dropdown-list componentId="ATC1Sensor" class="filters mb-4" :showSearch="true" :URLParams="true" :showCount="false" :placeholder="singleDropdownPlaceholder.atc1" @valueChange="atcSelectValueChanged($event, '1')" sortBy="asc" :dataField="`atc_1_${$i18n.locale.toUpperCase()}.keyword`" :title="$t('anatomical main group')" :filterLabel="$t('anatomical main group')" :react="{   and: ['ATC2Sensor', 'ATC3Sensor', 'ATC4Sensor', 'SearchSensor'] }"></single-dropdown-list>
              <single-dropdown-list componentId="ATC2Sensor" class="filters mb-4" :showSearch="true" :showMissing="true" :showCount="false" :URLParams="true" sortBy="asc" :placeholder="singleDropdownPlaceholder.atc2" @valueChange="atcSelectValueChanged($event, '2')" :transformData="transformATC2Data" :dataField="`atc_2_${$i18n.locale.toUpperCase()}.keyword`" :title="$t('therapeutic subgroup')" :filterLabel="$t('therapeutic subgroup')" :react="{   and: ['ATC1Sensor', 'ATC3Sensor', 'ATC4Sensor', 'SearchSensor'] }"></single-dropdown-list>
              <single-dropdown-list componentId="ATC3Sensor" class="filters mb-4" :showSearch="true" :showCount="false" :URLParams="true" sortBy="asc" :placeholder="singleDropdownPlaceholder.atc3" @valueChange="atcSelectValueChanged($event, '3')" :transformData="transformATC3Data" :dataField="`atc_3_${$i18n.locale.toUpperCase()}.keyword`" :title="$t('therapeutic/pharmacological subgroup')" :filterLabel="$t('therapeutic/pharmacological subgroup')" :react="{   and: ['ATC2Sensor', 'ATC1Sensor', 'ATC4Sensor', 'SearchSensor'] }"></single-dropdown-list>
              <single-dropdown-list componentId="ATC4Sensor" class="filters mb-4" :showSearch="true" :showCount="false" :URLParams="true" sortBy="asc" :placeholder="singleDropdownPlaceholder.atc4" :dataField="`atc_4_${$i18n.locale.toUpperCase()}.keyword`" @valueChange="atcSelectValueChanged($event, '4')" :transformData="transformATC4Data" :title="$t('chemical/therapeutic/pharmacological subgroup')" :filterLabel="$t('chemical/therapeutic/pharmacological subgroup')" :react="{   and: ['ATC2Sensor', 'ATC3Sensor', 'ATC1Sensor', 'SearchSensor'] }"></single-dropdown-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- resultados -->
    </reactive-base>
  </v-container>
</template>

<script>
import { CRED, TYPE, URL } from "@/helpers/constants";
import LikeRadioButtons from "@/components/mylogy/LikeRadioButtons";
import SearchbaseDrugResultItem from "@/components/mylogy/pgx_results/SearchbaseDrugResultItem";
import SearchbaseGeneResultItem from "@/components/mylogy/pgx_results/SearchbaseGeneResultItem";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import { mapGetters } from "vuex";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";

export default {
  components: {
    LikeRadioButtons,
    SearchbaseDrugResultItem,
    SearchbaseGeneResultItem,
    FondoCustom,
    PageTopTitle
  },
  data() {
    return {
      app: "drugs",
      selectedModel: {
        name: "FARMACOCINÉTICA + FARMACODINÁMICA",
        val: "mixed"
      },
      models: [
        { name: "FARMACOCINÉTICA + FARMACODINÁMICA", val: "mixed" },
        { name: "SOLO FARMACOCINÉTICA", val: "splitted" }
      ],
      searchBaseKey: 1,
      credentials: CRED,
      type: TYPE,
      url: URL,
      search: null,
      geneResults: {},
      panel: [0],
      atcSelectorSelected: {
        atc1: "",
        atc2: "",
        atc3: "",
        atc4: ""
      },
      singleDropdownPlaceholder: {
        atc1: null,
        atc2: null,
        atc3: null,
        atc4: null
      }
    };
  },
  computed: {
    ...mapGetters("pgx", ["getModel"]),
    sortOptions() {
      if (this.app == "drugs") {
        return [
          {
            sortBy: "asc",
            dataField: `${this.$i18n.locale}.keyword`,
            label: "ID"
          }
        ];
      } else {
        return [{ sortBy: "asc", dataField: "_id", label: "ID" }];
      }
    },
    searchPlaceholder() {
      if (this.app == "drugs") {
        return this.$t("buscar medicamentos");
      } else {
        return this.$t("buscar genes");
      }
    },
    reactiveListReact() {
      if (this.app == "drugs") {
        return {
          and: [
            "SearchSensor",
            "ATC1Sensor",
            "ATC2Sensor",
            "ATC3Sensor",
            "ATC4Sensor",
            "DrugSearchDisesasesSensor"
          ]
        };
      } else {
        return {
          and: ["SearchSensor", "PanelSensor", "GeneSearchDisesasesSensor"]
        };
      }
    },
    geneDiseaseDatafields() {
      return [
        `associated_disorders_${this.$i18n.locale.toUpperCase()}`,
        `associated_disorders_${this.$i18n.locale.toUpperCase()}.autosuggest`,
        `associated_disorders_${this.$i18n.locale.toUpperCase()}.delimiter`,
        `associated_disorders_${this.$i18n.locale.toUpperCase()}.lang`,
        `associated_disorders_${this.$i18n.locale.toUpperCase()}.synonyms`
      ];
    },
    drugDiseaseDatafields() {
      return [
        `ind_${this.$i18n.locale.toUpperCase()}`,
        `ind_${this.$i18n.locale.toUpperCase()}.delimiter`,
        `ind_${this.$i18n.locale.toUpperCase()}.lang`,
        `ind_${this.$i18n.locale.toUpperCase()}.synonyms`,
        `off_${this.$i18n.locale.toUpperCase()}`,
        `off_${this.$i18n.locale.toUpperCase()}.delimiter`,
        `off_${this.$i18n.locale.toUpperCase()}.lang`,
        `off_${this.$i18n.locale.toUpperCase()}.synonyms`,
        `off_${this.$i18n.locale.toUpperCase()}`
      ];
    },
    datafields() {
      if (this.app == "drugs") {
        return ["names", "names.autosuggest", `${this.$i18n.locale}`, `${this.$i18n.locale}.autosuggest`];
      } else {
        return [
          "alternative_names",
          "alternative_names.autosuggest",
          "alternative_names.delimiter",
          "alternative_names.lang",
          "alternative_names.synonyms",
          "alternative_symbols",
          "alternative_symbols.autosuggest",
          "alternative_symbols.delimiter",
          "alternative_symbols.lang",
          "alternative_symbols.synonyms",
          "gene",
          "gene.autosuggest",
          "gene.delimiter",
          "gene.lang",
          "gene.synonyms",
          "gene_id",
          "gene_id.autosuggest",
          "gene_id.delimiter",
          "gene_id.lang",
          "gene_id.synonyms",
          `name_${this.$i18n.locale}`,
          `name_${this.$i18n.locale}.autosuggest`,
          `name_${this.$i18n.locale}.delimiter`,
          `name_${this.$i18n.locale}.lang`,
          `name_${this.$i18n.locale}.synonyms`,
          "ommin",
          "ommin.autosuggest",
          "ommin.delimiter",
          "ommin.lang",
          "ommin.synonyms",
          "pharmgkb",
          "pharmgkb.autosuggest",
          "pharmgkb.delimiter",
          "pharmgkb.lang",
          "pharmgkb.synonyms"
        ];
      }
    }
  },
  mounted() {
    this.selectedModel = this.getModel;
    this.singleDropdownPlaceholder = {
      atc1: "",
      atc2: "",
      atc3: "",
      atc4: ""
    };
  },
  methods: {
    atcSelectValueChanged(value, level) {
      this.atcSelectorSelected[`atc${level}`] = this.$t(`${level}:${value}`);
    },
    transformATC2Data(data) {
      return data.filter(e => {
        return this.$t(`2:${e.key}`).includes(this.atcSelectorSelected[`atc1`]);
      });
    },
    transformATC3Data(data) {
      return data.filter(e => {
        return this.$t(`3:${e.key}`).includes(this.atcSelectorSelected[`atc2`]);
      });
    },
    transformATC4Data(data) {
      return data.filter(e => {
        return this.$t(`4:${e.key}`).includes(this.atcSelectorSelected[`atc3`]);
      });
    },
    updateModel(val) {
      this.$store.commit("pgx/SET_MODEL", val);
    },
    resultsDataChanged(data) {
      if (this.app == "drugs" && data.results && data.results.length == 1) {
        const atc1Placeholder =
          data.results[0][`atc_1_${this.$i18n.locale.toUpperCase()}`][0];
        const atc2Placeholder =
          data.results[0][`atc_2_${this.$i18n.locale.toUpperCase()}`][0];
        const atc3Placeholder =
          data.results[0][`atc_3_${this.$i18n.locale.toUpperCase()}`][0];
        const atc4Placeholder =
          data.results[0][`atc_4_${this.$i18n.locale.toUpperCase()}`][0];
        this.singleDropdownPlaceholder.atc1 = atc1Placeholder;
        this.singleDropdownPlaceholder.atc2 = atc2Placeholder;
        this.singleDropdownPlaceholder.atc3 = atc3Placeholder;
        this.singleDropdownPlaceholder.atc4 = atc4Placeholder;
      } else {
        if (this.app == "drugs" && data.results) {
          this.singleDropdownPlaceholder = {
            atc1: "",
            atc2: "",
            atc3: "",
            atc4: ""
          };
        }
      }
    },
    defaultQuery() {
      if (this.app == "drugs") {
        return {
          query: {
            match: { "card.keyword": "y" }
          }
        };
      } else {
        return {
          query: {
            match: { "has_pgx.keyword": "y" }
          }
        };
      }
    },
    buttonSelected(button) {
      this.app = button.key;
      this.searchBaseKey++;
    }
  }
};
</script>

<style lang="scss">
.search-bar .buttons button {
  flex-grow: 1;
  margin-right: 10px !important;
}
.searchbase-results {
  width: 100%;
}

/* Paginacion pagination */
.results .css-1oof9tm {
  a {
    background: transparent;
    color: #4d2cb9;
  }
  :focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.css-flmbq0 input,
.filters input,
.filters button {
  border-color: #4d2cb9;
}
.filters .css-iifocb {
  box-shadow: 0 0 20px -15px;
}

.filters ul {
  padding: 10px;
  border-radius: 25px;
  li {
    margin-top: 1em;
    line-height: 1em;
    border-radius: 3em;
    transition: 0.6s;
  }
  li:hover {
    transition: 0.4s;
  }
  label::before {
    border-radius: 15px;
    height: 20px !important;
    min-width: 20px;
    width: 20px !important;
    border-width: 2px;
    border-color: #4d2cb9 !important;
    transition: 0.4s;
    outline: none !important;
  }
  .active label::before {
    background: #5dffbd;
    border-color: #5dffbd !important;
    outline: none !important;
  }
  .active label::after {
    margin-left: 2px;
    transition: 0.4s;
  }
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
<style lang="scss">
.css-1xl6sbp * {
  font-size: none;
}
</style>
